<template>
  <vca-card>
    <h2>{{ $t("faqs.pool.title") }}</h2>
    <p v-html="$t('faqs.pool.description')" />
    <br />
    <Pool />
  </vca-card>
</template>
<script>
import Pool from "@/components/faqs/FaqsPool";
export default {
  name: "FaqsPool",
  components: { Pool },
};
</script>
